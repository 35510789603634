import { css } from "styled-components";

const lerpCss = ({
    from,
    to,
    fromWidth,
    toWidth,
    prop = "--lerp",
    cutoffMax,
    cutoffMin,
}: {
    from: number;
    to: number;
    fromWidth: number;
    toWidth: number;
    prop?: string;
    cutoffMin?: number;
    cutoffMax?: number;
}) => {
    const slope = `(${to} - ${from}) / (${toWidth} - ${fromWidth})`;
    const base = `(${from} - ${slope} * ${fromWidth})`;
    return css`
        ${cutoffMin &&
            css`
            @media (max-width: ${cutoffMin}px) {
                ${prop}: calc((${slope} * ${cutoffMin} + ${base}) * 1px);
            }`};
        ${prop}: calc(${slope} * 100vw + ${base} * 1px);
        ${cutoffMax &&
            css`
        @media (min-width: ${cutoffMax}px) {
            ${prop}: calc((${slope} * ${cutoffMax} + ${base}) * 1px);
        }
        `}
    `;
};
export default lerpCss;
