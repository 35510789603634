import { IsClientProvider } from "contexts/is-client";
import { Locale, LocaleProvider } from "contexts/locale-context";
import { LocationContextProvider } from "contexts/location-context";
import React, { PropsWithChildren, ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "styles/theme";
import CMSContentGuard from "./cms-content-guard";
import CMSContentProvider from "./cms-content-provider";

export default function Root({ children, locale, usesCMS }: PropsWithChildren<{ locale?: Locale; usesCMS?: boolean }>) {
    const wrapWithCMS = (element?: ReactNode) => (
        <CMSContentProvider>
            <CMSContentGuard>{element}</CMSContentGuard>
        </CMSContentProvider>
    );

    return (
        <LocationContextProvider>
            <IsClientProvider>
                <ThemeProvider theme={theme}>
                    <LocaleProvider locale={locale}>{usesCMS ? wrapWithCMS(children) : children}</LocaleProvider>
                </ThemeProvider>
            </IsClientProvider>
        </LocationContextProvider>
    );
}
