import isLocalLink from "./is-local-link";

export enum LinkType {
    Local,
    Hash,
    Remote,
}
export default function determineLinkType(link: string): LinkType {
    if (link.includes("#")) {
        return LinkType.Hash;
    }
    return isLocalLink(link) ? LinkType.Local : LinkType.Remote;
}
