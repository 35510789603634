import memoizeOne from "memoize-one";

export default class MQ {
    constructor(readonly minWidth: number, readonly maxWidth: number) {}

    extend(options: Partial<{ minWidth: number; maxWidth: number }>) {
        const newMin = options.minWidth === undefined ? this.minWidth : Math.min(options.minWidth, this.minWidth);
        const newMax = options.maxWidth === undefined ? this.maxWidth : Math.max(options.maxWidth, this.maxWidth);
        return new MQ(newMin, newMax);
    }

    exclude(options: Partial<{ minWidth: number; maxWidth: number }>): MQ {
        const isInside =
            options.minWidth !== undefined &&
            options.maxWidth !== undefined &&
            this.minWidth < options.minWidth &&
            options.maxWidth < this.maxWidth;
        if (isInside) {
            throw new Error("Cannot split media query into two sections");
        }
        const isOutside =
            (options.maxWidth !== undefined && options.maxWidth <= this.minWidth) ||
            (options.minWidth !== undefined && options.minWidth >= this.maxWidth);
        if (isOutside) {
            throw new Error("Media query is empty");
        }
        const excludeLeft = options.maxWidth !== undefined && options.maxWidth < this.maxWidth;
        const excludeRight = options.minWidth !== undefined && options.minWidth > this.minWidth;
        const newMin = excludeLeft ? Math.max(options.maxWidth || -Infinity, this.minWidth) : this.minWidth;
        const newMax = excludeRight ? Math.min(options.minWidth || Infinity, this.maxWidth) : this.maxWidth;
        return new MQ(newMin, newMax);
    }

    smallerThanThis = memoizeOne(() => {
        if (!isFinite(this.minWidth)) {
            throw new Error("Cannot go to left from -Infinity");
        }
        return new MQ(-Infinity, this.minWidth);
    });

    biggerThanThis = memoizeOne(() => {
        if (!isFinite(this.maxWidth)) {
            throw new Error("Cannot go to right from Infinity");
        }
        return new MQ(this.maxWidth, Infinity);
    });

    andUp = memoizeOne(() => {
        return new MQ(this.minWidth, Infinity);
    });

    andDown = memoizeOne(() => {
        return new MQ(-Infinity, this.maxWidth);
    });

    split(...breakpoints: number[]): MQ[] {
        return [this.minWidth, ...breakpoints].map((minWidth, i, minWidths) => {
            const maxWidth = (i === minWidths.length - 1 ? this.maxWidth : minWidths[i + 1]) - 1;
            return new MQ(minWidth, maxWidth);
        });
    }

    not = memoizeOne(() => {
        if (!isFinite(this.minWidth) && !isFinite(this.maxWidth)) {
            throw new Error("Negating media query would result in splitting in two");
        }
        return isFinite(this.minWidth) ? this.smallerThanThis() : this.biggerThanThis();
    });

    toString = memoizeOne(() => {
        const minPart = !isFinite(this.minWidth) ? "" : `(min-width: ${this.minWidth}px)`;
        const maxPart = !isFinite(this.maxWidth) ? "" : `(max-width: ${this.maxWidth}px)`;
        const hasBoth = minPart !== "" && maxPart !== "";
        return hasBoth ? `${minPart} and ${maxPart}` : `${minPart}${maxPart}`;
    });

    valueOf() {
        return this.toString();
    }

    static readonly all = new MQ(-Infinity, Infinity);

    static breakpoints(...breakpoints: number[]): MQ[] {
        return this.all.split(...breakpoints);
    }
}
