import { StaticContent } from "declarations/static-content";

const staticContentPl: StaticContent = {
    common: {
        appLinks: {
            appStore: "https://itunes.apple.com/us/app/activy/id1273195093?ls=1",
            googlePlay: "https://play.google.com/store/apps/details?id=com.activy",
        },
        locale: {
            gdansk: {
                fullName: "English",
            },
            sopot: {
                fullName: "Polski",
            },
        },
        cookie: {
            text:
                "Our website uses cookies to monitor traffic on our website and ensure that we can provide our customers with the best online experience possible.",
            agreeButton: "I agree",
        },
    },
    header: {
        nav: {
            prizesText: "Nagrody",
            termsAndConditionsText: "Zasady",
            FAQText: "FAQ",
            extraItems: [],
            joinContestButton: {
                link: "/",
                text: "Zapisz zespół",
            },
        },
    },
    footer: {
        text: "dla lepszego zdrowia, czystego środowiska i przyjaznych miast",
        contactInfoLines: ["Activy sp. z o.o. (KRS 0000645721)", "Plan Konesera 10 03-736 Warszawa"],
    },
    notFoundPage: {
        heading: "404: nie znaleziono",
        text: "Podany konkurs nie istnieje",
    },
    home: {
        banner: {
            competition: "WYZWANIE",
            organizedBy: "ORGANIZOWANY DLA",
        },
        results: {
            heading: "Aktualne wyniki konkursu",
            date: "Z dnia ",
            button: { text: "Sprawdź wyniki", link: "/" },
            stats: {
                cyclists: "rowerzystów",
                rides: "przejazdów",
                kilometers: "kilometrów",
                co2: "kg co2",
            },
        },
        whatIsThis: {
            heading: "Dołącz do zabawy!",
            littleTitle: "ROWEROWE WYZWANIE W FIRMIE",
            prevButton: "Poprzednie",
            nextButton: "Kolejne",
            slides: [
                {
                    color: "#ffa437",
                    heading: "Liczą się nie kilometry, ale Twoje zaangażowanie",
                    cards: [
                        {
                            text: "Zadbasz o swoją kondycję dobrze się bawiąc",
                            icon: "/images/home/what-is-this/cards/4.svg",
                        },
                        {
                            text: "Gra dla miejskiej rowerzystki jak i triathlonisty",
                            icon: "/images/home/what-is-this/cards/5.svg",
                        },
                        {
                            text: "Dodatkowe bonusy za dojazdy do pracy",
                            icon: "/images/home/what-is-this/cards/6.svg",
                        },
                    ],
                },
                {
                    color: "#3970ff",
                    heading: "Zbuduj zdrowy nawyk poprzez dobrą zabawę",
                    cards: [
                        {
                            text: "Sprawdzisz rankingi indywidualne i drużynowe",
                            icon: "/images/home/what-is-this/cards/7.svg",
                        },
                        {
                            text: "Zdobędziesz odznaki i kolejne poziomy",
                            icon: "/images/home/what-is-this/cards/8.svg",
                        },
                        {
                            text: "Twoje trasy nie są widoczne dla innych",
                            icon: "/images/home/what-is-this/cards/9.svg",
                        },
                    ],
                },
            ],
        },
        join: {
            text: "Dołącz do Karoliny i Moniki oraz 10 500 innych użytkowników. Przeczytaj ich inspirujące historie",
            avatars: ["/images/home/join/1.jpg", "/images/home/join/2.jpg"],
            button: {
                text: "Poczytaj o historiach ludzi, którzy się zaangażowali",
                link: "/",
            },
        },
        howToStart: {
            heading: "Jak wejść do gry?",
            steps: {
                employer: [
                    "Ściągnij aplikację i zarejestruj się",
                    "Wybierz konkurs z listy i wpisz kod dostępu",
                    "Zapisuj przejazdy, zdobywaj punkty",
                ],
                open: [
                    "Ściągnij aplikację i zarejestruj się",
                    "Wybierz wyzwanie z listy konkursów otwartych",
                    "Zapisuj przejazdy, zdobywaj punkty",
                ],
            },
            accessCodePart: {
                copy: "Skopiuj",
                getTheApp: "pobierz aplikację",
            },
        },
        rules: {
            heading: "Zasady",
            subheading: "Rowerowe wyzwanie, w którym liczą się nie tylko kilometry",
            pointsPerKm: null,
            pointsPerNormalRide: null,
            pointsStreak: null,
            pointsPerBonusRide: null,
            pointsCaption: "pkt",
            pointsPerKmText: "Za każdy kilometr w zapisanej trasie. To co, tym razem dłuższa wycieczka?",
            pointsPerNormalRideText:
                "Za każdy przejazd do lub z miejsca pracy, dłuższy niż 1 km. Aplikacja sama wykryje trasę do/z biura. Premia przyznawana max. 2 razy dziennie.",
            pointsPerBonusRideText:
                "Za każdy inny przejazd dłuższy niż 1 km. Nie dziel sztucznie tras - gra wymaga 20 min przerwy między przejazdami by naliczyć kolejne 10 punktów.",
        },
        editions: {
            heading: "EDYCJE WYZWANIA",
            text:
                "Konkurs jest podzielony na edycje. Po każdej edycji punkty się zerują, na końcu konkursu sumujemy wyniki wszystkich edycji w Klasyfikacji Generalnej. Bieżące wyniki możesz śledzić w aplikacji.",
            items: [],
        },
        ecoCharity: {
            divider: "to",
            co2: "kg co2",
            km: "km",
            ecoHeading: "Rowerem dla czystego środowiska",
            ecoText:
                "Czy wiesz, że rower jest 13-krotnie mniej emisyjnym środkeim transportu niż samochód? Dodatkowo zajmuje 10 razy mniej miejsca niż samochód i jest tańszy",
        },
        activyForYou: {
            heading: "APLIKACJA KONKURSOWA",
            text:
                "Activy nie jest aplikacją sportową. To wciągająca gra. Ważniejsza od dokładnych statystyk jest motywująca zabawa dla wszystkich.",
            list: [
                {
                    icon: "/images/home/activy-for-you/icon-1.svg",
                    text: "za zapisane przejazdy otrzymasz punkty w grze",
                },
                {
                    icon: "/images/home/activy-for-you/icon-2.svg",
                    text: "weźmiesz udział w rankingach indywidualnych, drużynowych i grupowych",
                },
                {
                    icon: "/images/home/activy-for-you/icon-3.svg",
                    text: "sprawdzisz statystyki indywidualne oraz Twojej firmy",
                },
                {
                    icon: "/images/home/activy-for-you/icon-1.svg",
                    text: "zdobędziesz odznaki, poziomy i walutę BikeCoin",
                },
                {
                    icon: "/images/home/activy-for-you/icon-2.svg",
                    text: "porównasz się z innymi, ale bez pokazywania Twoich mapek",
                },
            ],
        },
        strava: {
            cards: [
                {
                    text:
                        "Korzystasz z aplikacji Strava? Połącz ją z Activy. Wejdź w Ustawienia i kliknij przycisk łączenia. Uwaga! W Activy pojawią się jedynie trasy wykonane od momentu połączenia aplikacji.",
                    icon: "/images/home/strava/1.svg",
                },
                {
                    text:
                        "Używasz zegarka sportowego lub urządzenia z GPS np. Garmin? Importuj regularnie trasy do Stravie by uwzględnić je w grze Activy.",
                    icon: "/images/home/strava/2.svg",
                },
            ],
        },
        contact: {
            heading: "Kontakt",
            text: "Masz pytania dotyczące aplikacji i konkursu, napisz do nas.",
            cards: {
                customerCare: {
                    heading: "POMOC TECHNICZNA",
                    text: "lub napisz w aplikacji",
                },
                contact2: {
                    heading: "W SPRAWACH FIRMOWYCH SKONTAKTUJ\u00a0SIĘ\u00a0Z",
                },
            },
        },
    },
    scoresNotActive: {
        description: "Platforma wyników jest niedostępna.",
        header: "Platforma wyników",
    },
};
export default staticContentPl;
