import { useEffect, useState } from "react";

/**
 * Useful for detecting server/browser environment when employing SSR.
 * SSR renders only once, so the first render is with `isClient` set to `false`,
 * while subsequent ones are with `isClient` set to `true`.
 */
export default function useIsClient() {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return isClient;
}
