import lerpCss from "./lerp";

export const zeplinLerpValues = {
    fromWidth: 375,
    toWidth: 1600,
    cutoffMin: 375,
    cutoffMax: 1600,
};

const zeplinLerp = (prop: string, from: number, to: number) => lerpCss({ ...zeplinLerpValues, prop, from, to });
export default zeplinLerp;
