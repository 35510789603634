import { Location, LocationContext as LocationContextData } from "@reach/router";
import React, { createContext, PropsWithChildren, useContext } from "react";

const LocationContext = createContext<LocationContextData>({} as any);
export default LocationContext;

export function LocationContextProvider({ children }: PropsWithChildren<{}>) {
    return (
        <Location>
            {context => <LocationContext.Provider value={context}>{children}</LocationContext.Provider>}
        </Location>
    );
}

export function useLocation() {
    return useContext(LocationContext).location;
}
