import RootWrapper from "components/root";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Global from "styles/global";
import Cookie from "./cookie";
import Footer from "./footer";
import Header from "./header";

const StyledFooter = styled(Footer)``;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    & > main {
        flex: 1 1 auto;
    }

    & > ${StyledFooter} {
        flex: 0 0 auto;
    }
`;

const StyledCookie = styled(Cookie)`
    z-index: 1;
`;

type PropsOfMain = JSX.IntrinsicElements["main"];
export interface LayoutProps extends PropsOfMain {}
function Layout({ children, pageContext }) {
    return (
        <RootWrapper locale={pageContext.locale} usesCMS={pageContext.usesCMS}>
            <Root>
                <Global />
                <Header />
                <main style={{ zIndex: 1 }}>{children}</main>
                <StyledFooter />
                <StyledCookie />
            </Root>
        </RootWrapper>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
