import Color from "color";
import { Link } from "gatsby";
import useMediaSSR from "hooks/use-media-ssr";
import useMobileMenuOpenAttr from "hooks/use-mobile-menu-open-attr";
import { useScrollY } from "hooks/use-scroll-y";
import useStaticContent from "hooks/use-static-content";
import LogoSvg from "images/activy-logo.inline.svg";
import CloseIcon from "images/close.inline.svg";
import MenuIcon from "images/menu.inline.svg";
import React, { MutableRefObject, ReactNode, ReactText, useRef, useState } from "react";
import { animated, ReactSpringHook, useChain, useSpring } from "react-spring";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import { Theme, theme } from "styles/theme";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, padding, rootCss } from "styles/utils";
import ButtonLink from "./button-link";
import { useCMSContent } from "./cms-content-provider";
import LocaleSwitch from "./locale-switch";
import SvgIconButton from "./svg-icon-button";

const Root = styled.header`
    @media ${desktop.smallerThanThis().toString()} {
        position: absolute;
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    ${rootCss};

    height: var(--header-height);
    background-color: white;
    box-shadow: 0 1px 0 0
        ${p =>
            Color(p.theme.palette.fontDark)
                .alpha(0.1)
                .toString()};
`;

const Content = styled.div`
    height: 100%;
    ${padding.y("1rem")};
    ${contentCss};

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Logo = (() => {
    const LogoWrapper = styled.div`
        height: 100%;
        max-width: 200px;
    `;
    const LogoLink = styled(Link)`
        display: inline-block;
        height: 100%;
        --logo-aspect-ratio: 222/127;
        width: calc((var(--logo-aspect-ratio)) * (var(--header-height) - 2rem));
    `;
    const LogoImage = styled(LogoSvg).attrs({
        viewBox: "0 0 222.401 126.789",
    })`
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
    ` as typeof LogoSvg;

    return function Logo() {
        return (
            <LogoLink to="/">
                <LogoWrapper>
                    <LogoImage />
                </LogoWrapper>
            </LogoLink>
        );
    };
})();

const EmphasizedButton = styled(ButtonLink)`
    background-color: ${themeColor("orange")};
    box-shadow: 0 8px 24px 0
        ${p =>
            Color(p.theme.palette.orange)
                .alpha(0.15)
                .toString()};
    border-radius: 0.625rem;
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1rem;
    color: white;
    text-align: center;
    font-weight: bold;
    align-self: center;
`;

const MainActions = styled.div`
    display: flex;
    ${childSpacing({ horizontal: "2rem" })};
`;

const Nav = styled.nav``;

const emphasizedCss = (theme: Theme) => css`
    border-radius: 0.625rem;
    background-color: ${p => p.theme.palette.orange};
    box-shadow: 0 8px 24px 0
        ${p =>
            Color(p.theme.palette.orange)
                .alpha(0.15)
                .toString()};
    color: white;
`;

const NavLink = styled(ButtonLink)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: ${p => p.theme.palette.fontDark};
    padding: 1.25rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;

    &.emphasized {
        ${emphasizedCss(theme)}
    }
`;

type NavItemData = { text: ReactText; link: string };
function useNavItems() {
    const content = useStaticContent().header;
    const cmsContentState = useCMSContent();

    const navItems =
        cmsContentState.status !== "success"
            ? []
            : ([
                  cmsContentState.content.PrizesUrl && {
                      text: content.nav.prizesText,
                      link: cmsContentState.content.PrizesUrl,
                  },
                  cmsContentState.content.TermsAndConditionsUrl && {
                      text: content.nav.termsAndConditionsText,
                      link: cmsContentState.content.TermsAndConditionsUrl,
                  },
                  cmsContentState.content.FAQUrl && {
                      text: content.nav.FAQText,
                      link: cmsContentState.content.FAQUrl,
                  },
                  ...content.nav.extraItems,
              ].filter(Boolean) as NavItemData[]);
    return navItems;
}

interface ContentMobileProps {}
const ContentMobile = (() => {
    const Drawer = (() => {
        const Root = styled(animated.div)`
            position: fixed;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            background-color: white;
            &&& {
                margin: 0;
            }
        `;

        const ChildrenWrapper = styled(animated.div)`
            height: 100%;
        `;

        interface DrawerProps {
            open: boolean;
            onClose?(): void;
            children?: ReactNode;
        }
        return function Drawer(props: DrawerProps) {
            const spring1Ref = useRef<ReactSpringHook>() as MutableRefObject<ReactSpringHook>;
            const spring1 = useSpring({
                width: props.open ? "100vw" : "0vw",
                height: props.open ? "100vh" : "0vh",
                borderBottomLeftRadius: props.open ? "0vmin" : "100vmin",

                ref: spring1Ref,
                config: {
                    tension: 200,
                },
            });

            const spring2Ref = useRef<ReactSpringHook>() as MutableRefObject<ReactSpringHook>;
            const spring2 = useSpring({
                ref: spring2Ref,
                from: { opacity: 0 },
                to: {
                    opacity: props.open ? 1 : 0,
                },
                config: {
                    tension: 220,
                },
            });

            useChain(props.open ? [spring1Ref, spring2Ref] : [spring2Ref, spring1Ref]);

            return (
                <Root style={spring1}>
                    <ChildrenWrapper style={spring2}>{props.children}</ChildrenWrapper>
                </Root>
            );
        };
    })();

    const StyledMenuIcon = styled(MenuIcon)`
        * {
            transition: stroke ${themeTransition("fast")};
            stroke: ${themeColor("fontDark")};
        }
    ` as typeof MenuIcon;

    const MobileLocaleSwitch = styled.div`
        grid-area: locale;
        justify-self: center;
        display: flex;
    `;

    const MobileNav = styled.nav`
        grid-area: nav;
        display: flex;
        flex-direction: column;
        ${childSpacing({ vertical: "1rem" })};
    `;

    const MobileNavItem = styled(ButtonLink)`
        display: block;
        text-align: center;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 1rem 1.5rem;

        &.emphasized {
            ${p => shadowedButton({ color: p.theme.palette.orange })};
            color: white;
        }
    `;

    const DrawerContentWrapper = styled.div`
        ${rootCss}
    `;

    const DrawerContent = styled.div`
        ${contentCss};
        display: grid;
        grid-template-areas:
            "logo . close"
            "locale locale locale"
            "nav nav nav";
        grid-template-columns: auto 1fr auto;
        grid-template-rows: var(--header-height) auto;
        row-gap: 3rem;
    `;

    const DrawerLogoWrapper = styled.div`
        grid-area: logo;
        align-self: center;
        ${padding.y("1rem")};
    `;

    const CloseButtonWrapper = styled.div`
        grid-area: close;
        align-self: center;
        ${padding.y("1rem")};
    `;

    return function ContentMobile(props: ContentMobileProps) {
        const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
        useMobileMenuOpenAttr(drawerOpen);

        const content = useStaticContent().header.nav;

        const navItems = useNavItems();

        return (
            <>
                <Logo />
                <SvgIconButton onClick={() => setDrawerOpen(true)}>
                    <StyledMenuIcon />
                </SvgIconButton>
                <Drawer open={drawerOpen}>
                    <DrawerContentWrapper>
                        <DrawerContent>
                            <DrawerLogoWrapper>
                                <Logo />
                            </DrawerLogoWrapper>
                            <CloseButtonWrapper>
                                <SvgIconButton onClick={() => setDrawerOpen(false)}>
                                    <CloseIcon />
                                </SvgIconButton>
                            </CloseButtonWrapper>
                            <MobileLocaleSwitch>
                                <LocaleSwitch buttonsWithText />
                            </MobileLocaleSwitch>
                            <MobileNav>
                                {navItems.map(item => (
                                    <MobileNavItem key={item.text} link={item.link}>
                                        {item.text}
                                    </MobileNavItem>
                                ))}
                                <EmphasizedButton link={content.joinContestButton.link}>
                                    {content.joinContestButton.text}
                                </EmphasizedButton>
                            </MobileNav>
                        </DrawerContent>
                    </DrawerContentWrapper>
                </Drawer>
            </>
        );
    };
})();
interface ContentDesktopProps {}
const ContentDesktop = (() => {
    return function ContentDesktop(props: ContentDesktopProps) {
        const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);
        const scrollY = useScrollY();
        const scrollOffsetWhenSolid = isMobile ? 10 : 150;
        const transparent = scrollY < scrollOffsetWhenSolid;

        const content = useStaticContent().header.nav;

        const navItems = useNavItems();

        return (
            <>
                <Logo />
                <Nav>
                    {navItems.map(item => (
                        <NavLink key={item.text} link={item.link}>
                            {item.text}
                        </NavLink>
                    ))}
                </Nav>
                <MainActions>
                    <EmphasizedButton link={content.joinContestButton.link}>
                        {content.joinContestButton.text}
                    </EmphasizedButton>
                    <LocaleSwitch />
                </MainActions>
            </>
        );
    };
})();

function Header() {
    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

    return (
        <Root>
            <Content>{isMobile ? <ContentMobile /> : <ContentDesktop />}</Content>
        </Root>
    );
}

Header.propTypes = {};

export default Header;
