import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

export const IsClientContext = createContext<boolean>(false);

export function useIsClient() {
    return useContext(IsClientContext);
}

export function IsClientProvider(props: { children?: ReactNode }) {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return <IsClientContext.Provider value={isClient}>{props.children}</IsClientContext.Provider>;
}
