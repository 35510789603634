import { StaticContent } from "declarations/static-content";

const staticContentPl: StaticContent = {
    common: {
        appLinks: {
            appStore: "https://itunes.apple.com/us/app/activy/id1273195093?ls=1",
            googlePlay: "https://play.google.com/store/apps/details?id=com.activy",
        },
        locale: {
            gdansk: {
                fullName: "English",
            },
            sopot: {
                fullName: "Polski",
            },
        },
        cookie: {
            text:
                "Our website uses cookies to monitor traffic on our website and ensure that we can provide our customers with the best online experience possible.",
            agreeButton: "I agree",
        },
    },
    header: {
        nav: {
            prizesText: "Prizes",
            termsAndConditionsText: "Rules",
            FAQText: "FAQ",
            extraItems: [],
            joinContestButton: {
                link: "/",
                text: "Zapisz zespół",
            },
        },
    },
    footer: {
        text: "for better health, cleaner environment and happy cities",
        contactInfoLines: ["Activy sp. z o.o. (KRS 0000645721)", "Plan Konesera 10 03-736 Warsaw"],
    },
    notFoundPage: {
        heading: "404: not found",
        text: "Specified contest does not exist",
    },
    home: {
        banner: {
            competition: "CYCLING CHALLENGE",
            organizedBy: "ORGANIZED FOR",
        },
        results: {
            heading: "Current challenge statistics",
            date: "from date:",
            button: { text: "Sprawdź wyniki", link: "/" },
            stats: {
                cyclists: "PLAYERS",
                rides: "RIDES",
                kilometers: "KILOMETERS",
                co2: "kg co2",
            },
        },
        whatIsThis: {
            heading: "Join the game!",
            littleTitle: "COMPANY CYCLING CHALLENGE",
            prevButton: "Previous",
            nextButton: "Next",
            slides: [
                {
                    color: "#ffa437",
                    heading: "This game awards your engagement instead of kilometers",
                    cards: [
                        {
                            text: "you will keep fit by having fun",
                            icon: "/images/home/what-is-this/cards/4.svg",
                        },
                        {
                            text: "both for city cyclist and triathlonist",
                            icon: "/images/home/what-is-this/cards/5.svg",
                        },
                        {
                            text: "bonuses for biking to work",
                            icon: "/images/home/what-is-this/cards/6.svg",
                        },
                    ],
                },
                {
                    color: "#3970ff",
                    heading: "Build your healthy habit by having fun",
                    cards: [
                        {
                            text: "check individual and team leaderboards",
                            icon: "/images/home/what-is-this/cards/7.svg",
                        },
                        {
                            text: "get fun badges and hit higher levels",
                            icon: "/images/home/what-is-this/cards/8.svg",
                        },
                        {
                            text: "your trip maps will not be visible for others",
                            icon: "/images/home/what-is-this/cards/9.svg",
                        },
                    ],
                },
            ],
        },
        join: {
            text: "Dołącz do Karoliny i Moniki oraz 10 500 innych użytkowników. Przeczytaj ich inspirujące historie",
            avatars: ["/images/home/join/1.jpg", "/images/home/join/2.jpg"],
            button: {
                text: "Poczytaj o historiach ludzi, którzy się zaangażowali",
                link: "/",
            },
        },
        howToStart: {
            heading: "How to join?",
            steps: {
                open: [
                    "Download the app from your store and sign up",
                    "Choose your challenge from the list of the open contests",
                    "Save your rides, earn points, have fun",
                ],
                employer: [
                    "Download the app from your store and sign up",
                    "Choose your company and enter the code",
                    "Save your rides, earn points, have fun",
                ],
            },
            accessCodePart: {
                copy: "Copy",
                getTheApp: "get the app",
            },
        },
        rules: {
            heading: "Zasady",
            subheading: "Stop rawarding only for kilometers! You don't need to be sporty to win.",
            pointsPerKm: null,
            pointsPerNormalRide: null,
            pointsStreak: null,
            pointsPerBonusRide: null,
            pointsCaption: "pts",
            pointsPerKmText: "Za każdy kilometr w zapisanej trasie. To co, tym razem dłuższa wycieczka?",
            pointsPerNormalRideText:
                "Za każdy przejazd do lub z miejsca pracy, dłuższy niż 1 km. Aplikacja sama wykryje trasę do/z biura. Premia przyznawana max. 2 razy dziennie.",
            pointsPerBonusRideText:
                "Za każdy inny przejazd dłuższy niż 1 km. Nie dziel sztucznie tras - gra wymaga 20 min przerwy między przejazdami by naliczyć kolejne 10 punktów.",
        },
        editions: {
            heading: "CHALLENGE EDITIONS",
            text:
                "Each challenge is run in editions. After each edition all points are collected again from zero. All the stats from editions and general classification are available for you in app.",
            items: [],
        },
        ecoCharity: {
            divider: "to",
            co2: "kg co2",
            km: "km",
            ecoHeading: "Be eco and cool!",
            ecoText:
                "Cycling burns your calories, not fossil fuel. It reduces not only your stress but also air pollution and traffic jams in city. Be more eco-friendy. Stay healthy and cool.",
        },
        activyForYou: {
            heading: "CONTEST APP",
            text: "It's not a sport app but rather a motivating game. Let's make cycling fun and addictive.",
            list: [
                {
                    icon: "/images/home/activy-for-you/icon-1.svg",
                    text: "record your rides with GPS and earn points in the game",
                },
                {
                    icon: "/images/home/activy-for-you/icon-2.svg",
                    text: "take part in individual, team and griup leaderboards",
                },
                {
                    icon: "/images/home/activy-for-you/icon-3.svg",
                    text: "check your personal and company statistics",
                },
                {
                    icon: "/images/home/activy-for-you/icon-1.svg",
                    text: "get badges, hit new levels and earn BikeCoin currency",
                },
                {
                    icon: "/images/home/activy-for-you/icon-2.svg",
                    text: "compare with colleagues but hide your map trails",
                },
            ],
        },
        strava: {
            cards: [
                {
                    text:
                        "You can connect your Strava account with Activy. Go to Settings, choose Strava integration and click the connection button. All trips in Strava recorder after the integreation will automatically appear in Activy.",
                    icon: "/images/home/strava/1.svg",
                },
                {
                    text:
                        "Do you have sport watch or GPS device like Garmin? Connect Strava with Activy. Then import your rides regularly to make sure all of them earn points in the game.",
                    icon: "/images/home/strava/2.svg",
                },
            ],
        },
        contact: {
            heading: "Contact",
            text: "Have questions? Drop us a line.",
            cards: {
                customerCare: {
                    heading: "YOUR HELPDESK FOR APP AND CONTEST",
                    text: "or report a problem in app",
                },
                contact2: {
                    heading: "IN COMPANY MATTERS PLEASE CONTACT",
                },
            },
        },
    },
    scoresNotActive: {
        description: "Platforma wyników jest niedostępna.",
        header: "Platforma wyników",
    },
};
export default staticContentPl;
