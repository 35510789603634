import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import determineLinkType, { LinkType } from "utils/determine-link-type";

const Anchor = styled.a``;

export default function AutoLink({
    link,
    href,
    to,
    ...otherProps
}: { link?: string; to?: string } & Omit<PropsOf<"a">, "ref">) {
    const actualLink = link || to || href;
    switch (actualLink === undefined ? LinkType.Remote : determineLinkType(actualLink)) {
        case LinkType.Local:
            return <Link to={actualLink || ""} {...otherProps} />;
        case LinkType.Remote:
        case LinkType.Hash:
            return <Anchor href={actualLink} {...otherProps} target="_blank" />;
    }
}
