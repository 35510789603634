import styled from "styled-components";
import AutoLink from "./auto-link";

const ButtonLink = styled(AutoLink)`
    display: inline-block;
    text-decoration: none;
    color: inherit;
    text-align: center;
`;
export default ButtonLink;
