// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/home/komo/Documents/praca/leancode/grarowerowa/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-scores-not-active-tsx": () => import("/home/komo/Documents/praca/leancode/grarowerowa/src/pages/scores-not-active.tsx" /* webpackChunkName: "component---src-pages-scores-not-active-tsx" */),
  "component---src-pages-gdansk-tsx": () => import("/home/komo/Documents/praca/leancode/grarowerowa/src/pages/gdansk.tsx" /* webpackChunkName: "component---src-pages-gdansk-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/komo/Documents/praca/leancode/grarowerowa/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sopot-tsx": () => import("/home/komo/Documents/praca/leancode/grarowerowa/src/pages/sopot.tsx" /* webpackChunkName: "component---src-pages-sopot-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/komo/Documents/praca/leancode/grarowerowa/.cache/data.json")

