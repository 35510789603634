import { css } from "styled-components";

export const margin = (() => {
    const x = (value: string) => css`
        margin-left: ${value};
        margin-right: ${value};
    `;
    const centerX = x("auto");
    const y = (value: string) => css`
        margin-top: ${value};
        margin-bottom: ${value};
    `;

    return { x, centerX, y };
})();

export const padding = (() => {
    const x = (value: string) => css`
        padding-left: ${value};
        padding-right: ${value};
    `;
    const y = (value: string) => css`
        padding-top: ${value};
        padding-bottom: ${value};
    `;
    return { x, y };
})();

export const childSpacing = (options: { vertical?: string; horizontal?: string }) => css`
    > :not(:first-child) {
        ${options.vertical && `margin-top: ${options.vertical};`}
        ${options.horizontal && `margin-left: calc(${options.horizontal} / 2);`}
    }
    > :not(:last-child) {
        ${options.vertical && `margin-bottom: ${options.vertical};`}
        ${options.horizontal && `margin-right: calc(${options.horizontal} / 2);`}
    }
`;

export const setVarsCss = (vars: { [K: string]: string }) => css`
    ${Object.entries(vars)
        .map(([name, value]) => `--${name}: ${value};`)
        .join("\n")};
`;

export const contentCss = css`
    width: var(--preferred-content-width);
    max-width: 100%;
    ${margin.centerX};
`;

export const rootCss = css`
    ${padding.x("2rem")};
`;
