import styled from "styled-components";
import themeTransition from "styles/theme-transition";

const SvgIconButton = styled.button`
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: calc(1rem + 24px);
    height: calc(1rem + 24px);
    transition: all ${themeTransition("fast")};
    cursor: pointer;

    &:focus {
        outline: none;
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.15);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.25);
    }

    & > :only-child {
        width: 100%;
        height: 100%;
    }
`;
export default SvgIconButton;
