import { Redirect } from "@reach/router";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { useCMSContent } from "./cms-content-provider";

const Wrapper = styled.div<{ show?: boolean }>`
    display: ${p => (p.show ? "initial" : "none")};
`;

export default function CMSContentGuard({ children }: PropsWithChildren<{}>) {
    const cmsContentState = useCMSContent();

    if (cmsContentState.status === "invalid-domain-slug") {
        return <Redirect noThrow to="/404" />;
    }

    return <Wrapper show={cmsContentState.status === "success"}>{children}</Wrapper>;
}
