import { css } from "styled-components";
import MQ from "./mq";

export default function mediaSplit(...breakpoints: number[]) {
    return function mediaSplitInner(...cssRules: ReturnType<typeof css>[]) {
        const mqs = MQ.breakpoints(...breakpoints);
        return css`
            ${mqs.map(
                (mq, i) => css`
                    @media ${mq.toString()} {
                        ${cssRules[i]};
                    }
                `,
            )}
        `;
    };
}
