import Color from "color";
import { css } from "styled-components";

function shadowedButton({
    color,
    alpha = 0.15,
    borderRadius = "0.625rem",
}: {
    color: string;
    alpha?: number;
    borderRadius?: string;
}) {
    return css`
        background-color: ${color};
        box-shadow: 0 4px 8px 0
                ${Color(color)
                    .alpha(alpha)
                    .toString()},
            inset 0 -1px 3px 0 #0002;
        border-radius: ${borderRadius};
    `;
}

export default shadowedButton;
