import { Locale } from "contexts/locale-context";
import { Content } from "declarations/content";
import { createContext, useEffect, useMemo, useReducer } from "react";
import CMSApi, { LanguageDTO, domainSlugMap, ActivityType, ActivityFilterDTO } from "services/cms-api";

export interface ContentStateNone {
    status: "none";
}
export interface ContentStateLoading {
    status: "loading";
    locale: Locale;
    prevContent: Content | undefined;
}
export interface ContentStateSuccess {
    status: "success";
    locale: Locale;
    content: Content;
}
export interface ContentStateError {
    status: "error";
    locale: Locale;
    prevContent: Content | undefined;
}
export interface ContentStateInvalidDomainSlug {
    status: "invalid-domain-slug";
}

export type ContentState =
    | ContentStateNone
    | ContentStateLoading
    | ContentStateSuccess
    | ContentStateError
    | ContentStateInvalidDomainSlug;
type ContentAction =
    | {
          type: "startFetching";

          locale: Locale;
      }
    | {
          type: "success";
          locale: Locale;
          content: Content;
      }
    | {
          type: "error";
          locale: Locale;
      }
    | {
          type: "invalidDomainSlugError";
      };

const getPrevContent = (state: ContentState): Content | undefined => {
    switch (state.status) {
        case "none":
            return undefined;
        case "loading":
            return state.prevContent;
        case "success":
            return state.content;
        case "error":
            return undefined;
        case "invalid-domain-slug":
            return undefined;
    }
};

function useCMSApi(endpoint: string): CMSApi {
    return useMemo(() => new CMSApi(endpoint), [endpoint]);
}

function useCMSContentState(locale: Locale, domainSlug: string) {
    const [contentState, dispatch] = useReducer(
        (state: ContentState, action: ContentAction): ContentState => {
            switch (action.type) {
                case "startFetching":
                    return {
                        status: "loading",
                        locale: action.locale,
                        prevContent: getPrevContent(state),
                    };
                case "error":
                    return {
                        status: "error",
                        locale: action.locale,
                        prevContent: getPrevContent(state),
                    };
                case "success":
                    return {
                        status: "success",
                        locale: action.locale,
                        content: action.content,
                    };
                case "invalidDomainSlugError":
                    return {
                        status: "invalid-domain-slug",
                    };
            }
        },
        { status: "none" },
    );

    const contestsApi = "https://contests.v3.activy.pl/api";
    // const contestsApi = "https://contests.activy.lncd.pl/api";
    // const contestsApi = "https://contests.local.lncd.pl/api";
    const api = useCMSApi(typeof config === "undefined" ? "" : contestsApi);

    useEffect(() => {
        dispatch({
            type: "startFetching",
            locale,
        });
    }, [locale]);

    useEffect(() => {
        if (contentState.status === "loading") {
            Promise.all([
                api.contestLandingData({
                    DomainSlug: domainSlugMap[contentState.locale],
                    Language: LanguageDTO.Polish,
                }),
                api.contestLandingStatistics({
                    DomainSlug: domainSlugMap[contentState.locale],
                }),
            ])
                .then(response => {
                    const [content, stats] = response;
                    if (content.result === "success" && stats.result === "success") {
                        dispatch({
                            type: "success",
                            locale: contentState.locale,
                            content: {
                                ...content.data[ActivityType.Cycling],
                                ...stats.data[ActivityFilterDTO.Cycling],
                            },
                        });
                    } else {
                        dispatch({
                            type: "invalidDomainSlugError",
                        });
                    }
                })
                .catch(e => {
                    console.error(
                        `Error while fetching CMS content for domain ${domainSlug} and locale ${contentState.locale}.`,
                        e,
                    );
                    dispatch({
                        type: "error",
                        locale: contentState.locale,
                    });
                });
        }
    }, [api, contentState, domainSlug]);

    return contentState;
}

export const ContentContext = createContext<ContentState>({ status: "none" });

useCMSContentState.provider = ContentContext.Provider;

export default useCMSContentState;
