import { Link } from "gatsby";
import useStaticContent from "hooks/use-static-content";
import ActivyLogo from "images/activy-logo.inline.svg";
import cloud from "images/footer/cloud.svg";
import plantsGreen from "images/footer/plants-green.svg";
import plantsWhite from "images/footer/plants-white.svg";
import React, { forwardRef, Fragment, Ref } from "react";
import styled, { css } from "styled-components";
import contentArea from "styles/content-area";
import { desktop } from "styles/media-queries";
import mediaSplit from "styles/media-split";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.footer`
    background-image: linear-gradient(to top, #1f906c, #20926c 2%, #239d6c 25%, #25a46c 56%, #34a668);
    color: white;
    ${zeplinLerp("padding-top", 240, 260)};
    ${zeplinLerp("padding-bottom", 180, 290)};
`;

const LogoContainer = styled.div`
    width: 68px;
`;

const logoWidth = 222.401;
const logoHeight = 126.789;
const LogoLink = styled(Link)`
    display: inline-block;
    height: 100%;
    width: calc(${logoWidth} / ${logoHeight} * var(--logo-height));
`;

const Logo = styled(ActivyLogo).attrs({
    viewBox: `0 0 ${logoWidth} ${logoHeight}`,
})`
    width: 100%;
    height: 100%;
    .st0 {
        fill: white;
    }
` as typeof ActivyLogo;

const TextContainer = styled.div``;

const Text = styled.p`
    max-width: 350px;
    font-size: 1rem;
    line-height: 1.5;

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const Contact = styled.address`
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 2;
    letter-spacing: 1px;
    font-style: normal;

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const ContactLine = styled.div``;

const Content = styled.div`
    ${contentArea()};
    display: flex;
    position: relative;

    ${mediaSplit(desktop.minWidth)(
        css`
            flex-direction: column;
            align-items: center;
        `,
        css`
            & > ${LogoContainer} {
                flex: 0 0 auto;
                margin-right: 2rem;
            }
            & > ${TextContainer} {
                flex: 1;
            }
            & > ${Contact} {
                flex: 0 0 auto;
            }
        `,
    )}
`;

const Decorations = Fragment;
const Cloud = styled.img.attrs({
    src: cloud,
})`
    position: absolute;
    top: -150px;
    left: -210px;
    width: 152px;
    height: 47px;
`;
const PlantsGreen = styled.img.attrs({
    src: plantsGreen,
})`
    position: absolute;
    right: -190px;
    bottom: -156px;
    width: 145px;
    height: 277px;
`;
const PlantsWhite = styled.img.attrs({
    src: plantsWhite,
})`
    position: absolute;
    bottom: -180px;
    left: -210px;
    width: 109px;
    height: 195px;
`;

const Footer = forwardRef(function Footer(props: PropsOf<typeof Root>, ref: Ref<HTMLElement>) {
    const content = useStaticContent().footer;
    return (
        <Root {...props} ref={ref}>
            <Content>
                <Decorations>
                    <Cloud />
                    <PlantsGreen />
                    <PlantsWhite />
                </Decorations>
                <LogoContainer>
                    <LogoLink to="/">
                        <Logo />
                    </LogoLink>
                </LogoContainer>
                <TextContainer>
                    <Text>{content.text}</Text>
                </TextContainer>
                <Contact>
                    {content.contactInfoLines.map(line => (
                        <ContactLine key={line}>{line}</ContactLine>
                    ))}
                </Contact>
            </Content>
        </Root>
    );
});
export default Footer;
