import { Locale } from "contexts/locale-context";

export const domainSlugMap: { [K in Locale]: string } = {
    gdansk: "20afc1e4-210b-4fe9-82b4-2057107bb38a",
    sopot: "9d2f20a0-8e36-4832-87dd-90b9e05370e7",
};

type Int = number;
type Decimal = number;
type Double = number;
type Guid = string;

export enum ActivityType {
    Cycling = 0,
    Running = 1,
}

export enum ContestType {
    Employer = 0,
    Open = 1,
    League = 2,
    Challenge = 3,
}

export type ContestLandingDTO = {
    ContestType: ContestType;
    ActivityType: ActivityType;
    Enabled: boolean;
    ContestTitle: string;
    ContestSubtitle: string;
    Logo: string;

    TeamTitle: string;
    TeamSubtitle: string;
    TeamLogo: string;

    Code: string;
    HowToStart: string;
    EditionsDescription: string;

    PointsPerKm: Int;
    PointsPerNormalRide: Int;
    PointsPerBonusRide: Int;

    PointsPerKmDescription: string;
    PointsPerNormalRideDescription: string;
    PointsPerBonusRideDescription: string;
    PointsStreak: string;
    PointsStreakDescription: string;
    PointsOverallDescription: string;

    Section: ContestLandingSectionsDTO;

    EcoKM: Int;
    EcoCO2: Decimal;

    CharityKM: Int;
    CharityKcal: Int;
    CharityMoney: Decimal;
    SectionTitle: string;
    SectionSubtitle: string;
    CharityCurrency: string;

    CustomerCareEmail: string;
    CompanyCareName: string;
    CompanyCareEmail: string;
    CompanyCareGender: ContestLandingGenderDTO;

    PrizesUrl: string;
    TermsAndConditionsUrl: string;
    FAQUrl: string;

    Editions: EditionSpecificationDTO[];
};

export type ContestLandingStatisticsDTO = {
    ContestStatsPlayersCount: Int;
    ContestStatsRidesCount: Int;
    ContestStatsTotalDistanceKm: Double;
    ContestStatsTotalSavedCO2Kg: Double;
};

export interface EditionSpecificationDTO {
    Order: Int;
    Name: string;
    StartDate: string;
    EndDate: string;
}

export enum ActivityFilterDTO {
    All = -1,
    Cycling = 0,
    Running = 1,
}

export enum ContestLandingSectionsDTO {
    Eco = 0,
    Charity = 1,
    KcalCharity = 2,
}

export enum ContestLandingGenderDTO {
    Man = 0,
    Woman = 1,
}

export enum LanguageDTO {
    English = 0,
    Polish = 1,
}

export type ContestLandingDataParams = {
    DomainSlug: string;
    Language: LanguageDTO;
};

export type ContestLandingStatisticsParams = {
    DomainSlug: string;
};

type ContestLandingDataResponse =
    | { result: "success"; data: Record<ActivityType, ContestLandingDTO> }
    | { result: "slugDoesNotExist" };

type ContestLandingStatisticsResponse =
    | { result: "success"; data: Record<ActivityFilterDTO, ContestLandingStatisticsDTO> }
    | { result: "slugDoesNotExist" };

export default class CMSApi {
    constructor(readonly endpoint: string) {}

    async contestLandingData(params: ContestLandingDataParams): Promise<ContestLandingDataResponse> {
        const url = `${this.endpoint}/query/Activy.Contests.Contracts.Mobile.Contests.ContestLandingData`;
        const response = await fetch(url, {
            body: JSON.stringify(params),
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error(`${this.contestLandingData.name}: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        if (data === null) {
            return { result: "slugDoesNotExist" };
        }
        return { result: "success", data };
    }

    async contestLandingStatistics(params: ContestLandingStatisticsParams): Promise<ContestLandingStatisticsResponse> {
        const url = `${this.endpoint}/query/Activy.Contests.Contracts.Mobile.Contests.ContestLandingStatistics`;
        const response = await fetch(url, {
            body: JSON.stringify(params),
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error(`${this.contestLandingStatistics.name}: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        if (data === null) {
            return { result: "slugDoesNotExist" };
        }
        return { result: "success", data };
    }
}

export type EcoCharityVariant = "eco" | "charity" | "kcal-charity";
export class SectionTypeConverter {
    static fromDTO(dto: ContestLandingSectionsDTO): EcoCharityVariant {
        switch (dto) {
            case ContestLandingSectionsDTO.Eco:
                return "eco";
            case ContestLandingSectionsDTO.Charity:
                return "charity";
            case ContestLandingSectionsDTO.KcalCharity:
                return "kcal-charity";
        }
    }

    static toDTO(sectionType: EcoCharityVariant) {
        switch (sectionType) {
            case "eco":
                return ContestLandingSectionsDTO.Eco;
            case "charity":
                return ContestLandingSectionsDTO.Charity;
            case "kcal-charity":
                return ContestLandingSectionsDTO.KcalCharity;
        }
    }
}
