import { css } from "styled-components";
import { desktop } from "./media-queries";
import { margin, padding } from "./utils";

const paddingX = "2rem";
const contentArea = (overrideWidth: { [MQ: string]: string } = {}) => css`
    max-width: 100%;
    ${padding.x(paddingX)};
    ${margin.centerX};
    ${overrideWidth["default"] && `--preferred-content-width: ${overrideWidth["default"]}`};
    /* No CSS variables fallback */
    width: calc(${overrideWidth["default"] || "1000px"} + ${paddingX});
    width: calc(var(--preferred-content-width) + ${paddingX});

    @media ${desktop.smallerThanThis().toString()} {
        width: calc(600px + ${paddingX});
        width: calc(var(--preferred-content-width) + ${paddingX});
    }

    ${Object.keys(overrideWidth).map(
        mq => css`
            @media ${mq} {
                --preferred-content-width: ${overrideWidth[mq]};
                width: calc(${overrideWidth[mq]} + ${paddingX});
                width: calc(var(--preferred-content-width) + ${paddingX});
            }
        `,
    )}
`;

export default contentArea;
