import { css } from "styled-components";

/**
 * Just write the values as they are in Zeplin in px
 * @param options
 */
const zeplinTypography = (
    options: { fontSize: number } & Partial<{ lineHeight: number; letterSpacing: number }>,
) => css`
    --font-size: calc(${options.fontSize} / 16 * 1rem);
    font-size: var(--font-size);
    ${options.lineHeight &&
        css`
            --line-height: calc(${options.lineHeight} / ${options.fontSize});
            line-height: var(--line-height);
        `};
    ${options.letterSpacing &&
        css`
            letter-spacing: calc(${options.letterSpacing} / 16 * 1rem);
        `};
`;
export default zeplinTypography;
