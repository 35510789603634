import styled from "styled-components";

const CleanButton = styled.button`
    background: none;
    outline: none;
    border: none;
    box-shadow: none;

    cursor: pointer;
`;
export default CleanButton;
