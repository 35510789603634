import { useEffect, useState, useCallback } from "react";

export function useScrollY() {
    const [scrollY, setScrollY] = useState(0);

    const updateScrollY = useCallback(() => {
        setScrollY(window.scrollY);
    }, [setScrollY]);

    useEffect(() => {
        addEventListener("scroll", updateScrollY);
        return () => removeEventListener("scroll", updateScrollY);
    }, [updateScrollY]);

    useEffect(() => {
        typeof window !== "undefined" && updateScrollY();
    });

    return scrollY;
}
