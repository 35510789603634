import { css } from "styled-components";
import { Theme } from "./theme";

export default function themeTransition(
    speed: keyof Theme["transitions"]["speed"] = "default",
    fn: keyof Theme["transitions"]["fn"] = "default",
) {
    const speedKeyGetter = (theme: Theme) =>
        theme.transitions.speed[speed === "default" ? theme.transitions.speed["default"] : speed];
    const fnKeyGetter = (theme: Theme) => theme.transitions.fn[fn === "default" ? theme.transitions.fn["default"] : fn];
    return css`
        ${p => speedKeyGetter(p.theme)} ${p => fnKeyGetter(p.theme)}
    `;
}
