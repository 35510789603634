import { Redirect } from "@reach/router";
import { navigate } from "gatsby";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect } from "react";
import switchLangUrl from "utils/switch-lang-url";
import { useIsClient } from "./is-client";
import { useLocation } from "./location-context";

export type Locale = "gdansk" | "sopot";
export const locales: Locale[] = ["gdansk", "sopot"];

export const LocaleContext = createContext<Locale>("gdansk");
export const SetLocaleContext = createContext<(v: Locale) => void>(() => {});

export function useSetLocale() {
    return useContext(SetLocaleContext);
}

export function useLocale() {
    return useContext(LocaleContext);
}

export function LocaleProvider({ children, locale }: PropsWithChildren<{ locale?: Locale }>) {
    const isClient = useIsClient();
    const location = useLocation();

    const setLocale = useCallback(
        (locale: Locale) => {
            navigate(switchLangUrl(location.pathname, locale));
        },
        [location],
    );

    return (
        <LocaleContext.Provider value={locale || "gdansk"}>
            <SetLocaleContext.Provider value={setLocale}>{children}</SetLocaleContext.Provider>
        </LocaleContext.Provider>
    );
}
