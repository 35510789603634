import { anyDesktop, anyMobile, desktop, desktopLg, mobile, tabletLandscape, tabletPortrait } from "./media-queries";

export const theme = {
    mq: {
        mobile,
        tabletPortrait,
        tabletLandscape,
        desktop,
        desktopLg,
        anyMobile,
        anyDesktop,
    },
    palette: {
        green: "#2ecc70",
        blue: "#3970ff",
        orange: "#ffa437",
        backgroundLight: "#f6f8fa",
        backgroundDark: "#383e4a",
        fontLight: "#ffffff",
        fontDark: "#383e4a",
        fontSemiLight: "#a3adba",
        fontGrey: "#6e7579",
        fontSemiDark: "#8e97a9",
        disabled: "#f0f3f6",
        darkGreen: "#0e7457",
        veryLightBlue: "#dcedff",
        borderGrey: "#e1e6ec",
    },
    transitions: {
        speed: {
            default: "fast",
            slow: "0.7s",
            normal: "0.5s",
            fast: "0.3s",
        },
        fn: {
            default: "ease",
            ease: "ease",
        },
    },
};

export type Theme = Readonly<typeof theme>;
