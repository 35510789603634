import { useLocale } from "contexts/locale-context";
import { useLocation } from "contexts/location-context";
import useCMSContentState, { ContentState } from "hooks/use-cms-content-state";
import React, { createContext, ReactNode, useContext } from "react";

export const CMSContentContext = createContext<ContentState>({ status: "none" });

export default function CMSContentProvider({ children }: { children?: ReactNode }) {
    const locale = useLocale();
    const location = useLocation();
    const brand = typeof location.host === "undefined" ? "" : location.host.split(".")[0];
    const contentState = useCMSContentState(locale, brand);

    return <CMSContentContext.Provider value={contentState}>{children}</CMSContentContext.Provider>;
}

export function useCMSContent() {
    return useContext(CMSContentContext);
}
