import { Locale, useLocale } from "contexts/locale-context";
import { StaticContent } from "declarations/static-content";
import { withPrefix } from "gatsby";
import { useEffect, useReducer, useRef, useState } from "react";
import staticContentGdansk from "services/static-content/gdansk";
import staticContentSopot from "services/static-content/sopot";

type FetchStateAction =
    | {
          type: "fetch";
          locale: Locale;
      }
    | {
          type: "clear";
      };
type FetchState =
    | {
          status: "pending";
          locale: Locale;
      }
    | { status: "loaded" };

const staticContentMap: { [K in Locale]: StaticContent } = {
    gdansk: staticContentGdansk,
    sopot: staticContentSopot,
};

export default function useStaticContent() {
    const locale = useLocale();
    const [content, setContent] = useState<StaticContent>(staticContentMap[locale]);
    const [fetchState, dispatch] = useReducer(
        (state: FetchState, action: FetchStateAction): FetchState => {
            switch (action.type) {
                case "fetch":
                    return {
                        status: "pending",
                        locale: action.locale,
                    };
                case "clear":
                    return { status: "loaded" };
            }
        },
        { status: "loaded" },
    );
    const allowContentAssignment = useRef<boolean>(true);

    useEffect(() => {
        switch (fetchState.status) {
            case "pending":
                allowContentAssignment.current = true;
                fetch(withPrefix(`/content/content-${locale}.json`))
                    .then(e => e.json())
                    .then(content => allowContentAssignment.current && setContent(content))
                    .catch(e => console.error(`Error while fetching static content for locale ${locale}.`, e));
                return;
            case "loaded":
                dispatch({
                    type: "clear",
                });
                return;
        }
        return () => {
            allowContentAssignment.current = false;
        };
    }, [fetchState.status, locale]);

    useEffect(() => {
        dispatch({
            type: "fetch",
            locale,
        });
    }, [locale]);

    return content;
}
