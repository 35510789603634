import { MediaQueryMatchers } from "declarations/media-query-matchers";
import matchMedia from "matchmediaquery";
import { useCallback, useEffect, useMemo, useState } from "react";
import useIsClient from "./use-is-client";

export default function useMediaSSR(mq: string, ssrValues: MediaQueryMatchers): boolean {
    const isClient = useIsClient();
    const mediaQueryList = useMemo(() => matchMedia(mq, ssrValues, !isClient), [mq, ssrValues, isClient]);
    const [_matches, setMatches] = useState(mediaQueryList.matches);

    const onChangeCallback = useCallback(
        function(this: MediaQueryList) {
            setMatches(this.matches);
        },
        [setMatches],
    );

    useEffect(() => {
        mediaQueryList.addListener(onChangeCallback);
        setMatches(mediaQueryList.matches);
        return () => {
            mediaQueryList.removeListener(onChangeCallback);
        };
    }, [mediaQueryList, onChangeCallback]);

    return mediaQueryList.matches;
}
