import { css } from "styled-components";
import latoBlack from "../fonts/Lato-Black.ttf";
import latoBlackItalic from "../fonts/Lato-BlackItalic.ttf";
import latoBold from "../fonts/Lato-Bold.ttf";
import latoBoldItalic from "../fonts/Lato-BoldItalic.ttf";
import latoItalic from "../fonts/Lato-Italic.ttf";
import latoLight from "../fonts/Lato-Light.ttf";
import latoLightItalic from "../fonts/Lato-LightItalic.ttf";
import latoRegular from "../fonts/Lato-Regular.ttf";

export enum FontFamily {
    Lato = "Lato",
}

export const fontsCss = css`
    @font-face {
        font-family: Lato;
        src: url(${latoLight});
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoLightItalic});
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoRegular});
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoItalic});
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoBold});
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoBoldItalic});
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoBlack});
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: Lato;
        src: url(${latoBlackItalic});
        font-weight: 900;
        font-style: italic;
    }
`;
