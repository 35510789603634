import classNames from "classnames";
import { Locale, locales, useLocale, useSetLocale } from "contexts/locale-context";
import useStaticContent from "hooks/use-static-content";
import gdanskIcon from "images/locale/gdansk-logo.png";
import sopotIcon from "images/locale/sopot-logo.png";
import React from "react";
import styled from "styled-components";
import themeTransition from "styles/theme-transition";
import { childSpacing } from "styles/utils";
import zeplinTypography from "styles/zeplin-typography";
import CleanButton from "./clean-button";

const LocaleIcon = styled.img`
    width: 20px;
    height: 20px;
    object-position: center;
`;

const LocaleText = styled.span`
    ${zeplinTypography({
        fontSize: 16,
        lineHeight: 16,
    })};
    font-weight: bold;
    margin-left: 1rem;
`;

interface LocaleElementProps {
    locale: Locale;
    long?: boolean;
}
function LocaleElement(props: LocaleElementProps) {
    const localeText = useStaticContent().common.locale[props.locale].fullName;
    const iconMap: { [K in Locale]: string } = {
        gdansk: gdanskIcon,
        sopot: sopotIcon,
    };
    const iconSrc = iconMap[props.locale];

    return (
        <>
            <LocaleIcon src={iconSrc} />
            {props.long && <LocaleText>{localeText}</LocaleText>}
        </>
    );
}

const LocaleButton = styled(CleanButton)`
    display: flex;
    align-items: center;
    padding: 0.875rem;
    background-color: transparent;
    border: 1px solid;
    border-color: transparent;
    box-shadow: inset 0 -1px 3px 0 rgba(210, 216, 224, 0.35);
    transition: all ${themeTransition("fast")};

    &:hover {
        background-color: #0003;
    }
    &:active {
        background-color: #0004;
    }

    &.selected {
        background-color: white;
    }

    &.circle {
        border-radius: 50%;
        &.selected {
            background-color: #0001;
        }
    }

    &.outlined {
        border-color: lightgrey;
    }
`;

const CirclesVariantRoot = styled.div`
    display: flex;
    ${childSpacing({ horizontal: "0.75rem" })}
`;

const ButtonGroupVariantRoot = styled.div`
    display: inline-flex;
    ${LocaleButton} {
        border-radius: 0.625rem;
        &:not(:first-of-type) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &:not(:last-of-type) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.selected {
            background-color: #0003;
        }
    }
`;

const Root = styled.div``;

export interface LocaleSwitchProps extends Partial<PropsOf<typeof Root>> {
    buttonsWithText?: boolean;
}
function LocaleSwitch({ buttonsWithText, ...rootProps }: LocaleSwitchProps) {
    const setLocale = useSetLocale();
    const currentLocale = useLocale();
    const children = locales.map(locale => (
        <LocaleButton
            key={locale}
            className={classNames({
                selected: locale === currentLocale,
                circle: !buttonsWithText,
                outlined: true,
            })}
            onClick={() => setLocale(locale)}
        >
            <LocaleElement locale={locale} long={buttonsWithText} />
        </LocaleButton>
    ));
    return !buttonsWithText ? (
        <CirclesVariantRoot {...rootProps}>{children}</CirclesVariantRoot>
    ) : (
        <ButtonGroupVariantRoot {...rootProps}>{children}</ButtonGroupVariantRoot>
    );
}
export default LocaleSwitch;
