// import Button from "./button";
// import { ContentContext } from "hooks/use-content";
import color from "color";
import useStaticContent from "hooks/use-static-content";
import React, { useEffect, useState } from "react";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import shadowedButton from "styles/shadowed-button";
import CleanButton from "./clean-button";

const Root = styled(animated.div)`
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    padding: 1rem;
    box-shadow: 0 8px 4px 0 #0002;
    border: 1px solid lightgrey;
`;

const Text = styled.p``;
const AgreeButton = styled(CleanButton)`
    ${p => shadowedButton({ color: p.theme.palette.blue })};
    padding: 0.75rem 2.5rem;
    color: white;
    :hover {
        background-color: ${p =>
            color(p.theme.palette.blue)
                .lighten(0.2)
                .hex()};
    }
    :active {
        background-color: ${p =>
            color(p.theme.palette.blue)
                .darken(0.2)
                .hex()};
    }
`;

function Cookie({ className }: { className?: string }) {
    const [show, setShow] = useState(true);
    const transition = useTransition(show, null, {
        from: {
            bottom: "-10rem",
            opacity: 0,
        },
        enter: {
            bottom: "1rem",
            opacity: 1,
        },
        leave: {
            bottom: "-10rem",
            opacity: 0,
        },
    });
    const content = useStaticContent().common.cookie;

    useEffect(() => {
        if (show && localStorage.getItem("gdpr-consent") === "true") {
            setShow(false);
        }
        if (!show) {
            localStorage.setItem("gdpr-consent", "true");
        }
    }, [show, setShow]);

    return (
        <>
            {transition.map(
                ({ item: show, props, key }) =>
                    show && (
                        <Root key={key} style={props} className={className}>
                            <Text>{content.text}</Text>
                            <AgreeButton onClick={() => setShow(false)}>{content.agreeButton}</AgreeButton>
                        </Root>
                    ),
            )}
        </>
    );
}
export default Cookie;
